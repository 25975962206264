import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from '../components/container'
import DemoForm from '../components/demo_form'
import SimpleHeader from '../components/simple_header'

const WatchDemoPage = ({ data }) => {

  const content = data.contentfulPage

  return (
    <Layout>
      <Seo
        title={content.seoTitle || content.title}
        description={content.metaDescription || content.subtitle}
      />
      <SimpleHeader
        title={content.title}
        subtitle={content.subtitle}
      />
      <div className='bg-near-white pb-4'>
        <Container size='lg'>
          <h2 className='text-xl sm:text-2xl mt-0 font-regular text-center pt-4 sm:pt-5 mb-4'>
            Select a Region Below to Watch a Demo
          </h2>
          <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
            {content.items.map(item =>
              <a
                key={`watch_demo_content_item_${item.url}`}
                href={item.url}
                rel='noopener noreferrer'
                target='_blank'
                className='flex flex-col bg-white text-gray hover:text-black shadow-sm hover:shadow-lg transition-shadow'>
                <div className='p-3'>
                  <h3 className='m-0 text-black'>
                    {item.title}
                  </h3>
                  {item.blurb &&
                    <p className='text-lg m-0 mt-1/4em'>
                      {item.blurb}
                    </p>
                  }
                </div>
                {item.image &&
                  <GatsbyImage
                    alt=''
                    image={item.image.gatsbyImageData}
                    className='mt-auto'
                  />
                }
              </a>
            )}
          </div>
        </Container>
      </div>
      <div className='Section Section--dark'>
        <Container>
          <DemoForm invert={true} />
        </Container>
      </div>
    </Layout>
  )
}

export default WatchDemoPage

export const pageQuery = graphql`
  query {
    contentfulPage(url: {eq: "watch_demo"}) {
      title
      seoTitle
      metaDescription
      subtitle
      items {
        ... on ContentfulResourceItem {
          url
          blurb
          title
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
